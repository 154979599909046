<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenService.auction_sale') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
         <b-row>
           <b-col xs="12" sm="12" md="4" lg="4" xl="4">
             <b-form-group
               label-for="auction_no"
             >
               <template v-slot:label>
                 {{ $t('teaGardenService.auction_no') }}
               </template>
               <b-form-input
                 id="auction_no"
                 type="text"
                 v-model="search.auction_no"
               ></b-form-input>
             </b-form-group>
           </b-col>
           <b-col xs="12" sm="12" md="4" lg="4" xl="4">
             <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="">
               <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                 <template v-slot:label>
                   {{ $t('teaGardenService.season') }}
                 </template>
                 <b-form-select
                   plain
                   id="fiscal_year_id"
                   v-model="search.fiscal_year_id"
                   :options="fiscalYearList"
                   :state="errors[0] ? false : (valid ? true : null)"
                 >
                   <template v-slot:first>
                     <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                   </template>
                 </b-form-select>
                 <div class="invalid-feedback d-block">
                   {{ errors[0] }}
                 </div>
               </b-form-group>
             </ValidationProvider>
           </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-group
              label-for="auction_location"
            >
              <template v-slot:label>
                {{ $t('teaGardenService.location') }}
              </template>
            <b-form-select
                plain
                v-model="search.auction_location"
                :options="locationList"
                id="auction_location"
                >
            <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select>
            </b-form-group>
          </b-col>
           <b-col xs="12" sm="12" md="4" lg="4" xl="4">
             <ValidationProvider name="Auction Date" vid="auction_date" rules="">
               <b-form-group
                 class="row"
                 label-for="auction_date"
                 slot-scope="{ valid, errors }">
                 <template v-slot:label>
                   {{ $t('teaGardenService.auction_date') }}
                 </template>
                 <date-picker
                   id="auction_date"
                   v-model="search.auction_date"
                   class="form-control"
                   :placeholder="$t('globalTrans.select_date')"
                   :state="errors[0] ? false : (valid ? true : null)"
                   :class="errors[0] ? 'is-invalid' : ''"
                   :locale="currentLocale"
                 >
                 </date-picker>
                 <div class="invalid-feedback d-block">
                   {{ errors[0] }}
                 </div>
               </b-form-group>
             </ValidationProvider>
           </b-col>
           <b-col xs="12" sm="12" md="4" lg="4" xl="4">
             <b-form-group
               label-for="broker_id"
             >
               <template v-slot:label>
                 {{ $t('teaGardenConfig.broker_name') }}
               </template>
               <b-form-select
                 plain
                 v-model="search.broker_id"
                 :options="masterBrokerInfoList"
                 id="broker_id"
               >
                 <template v-slot:first>
                   <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                 </template>
               </b-form-select>
             </b-form-group>
           </b-col>
            <b-col sm="4" class="mt-4">
                <b-button size="sm" variant="primary" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
            </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenService.auction_sale') }} {{ $t('globalTrans.list') }}</h4>
      </template>
        <template v-slot:headerAction>
        <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                   <template v-slot:cell(auction_date)="data">
                    {{ data.item.auction_date | dateFormat }}
                  </template>
                  <template v-slot:cell(fiscal_year_id)="data">
                    {{ getFiscalYearName(data.item.fiscal_year_id) }}
                  </template>
                  <template v-slot:cell(broker_id)="data">
                    {{ getBrokerName(data.item.broker_id) }}
                  </template>
                  <template v-slot:cell(auction_location)="data">
                    {{ getAuctionLocationName(data.item.auction_location) }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-primary" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                    <span class="badge badge-danger" v-else-if="data.item.status == 2">{{$t('globalTrans.inactive')}}</span>
                    <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button class="moc-action-btn moc-view-btn" v-b-modal.modal-form-details @click="details(data.item)" :title="$t('globalTrans.view')">
                      <i class="ri-eye-line"></i>
                    </b-button>
                    <b-button class="moc-action-btn moc-edit-btn" v-b-modal.modal-form @click="edit(data.item)" v-if="data.item.status == 2" :title="$t('globalTrans.edit')">
                      <i class="ri-pencil-fill"></i>
                    </b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end-->
    </body-card>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-form-details" size="lg" :title="detailsTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details  :item="item" :key="editItemId" ref="details"/>
    </b-modal>
  </div>
</template>
<script>
  import Form from './Form.vue'
  import Details from './Details'
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { auctionSaleList, brokersInfoToggleApi } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form, Details
    },
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          auction_no: '',
          fiscal_year_id: 0,
          auction_date: '',
          auction_location: 0,
          broker_id: 0,
          limit: 10
        },
        item: '',
        editItemId: 0,
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '8%' } },
          { labels: 'teaGardenService.auction_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: 'auto' } },
          { labels: 'teaGardenService.season', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
          { labels: 'teaGardenService.auction_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
          { labels: 'teaGardenService.auction_location', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
          { labels: 'teaGardenConfig.broker_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '15%' } },
          { labels: 'teaGardenService.total_sale_quantity', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '15%' } },
          { labels: 'teaGardenService.total_average_value', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '15%' } },
          { labels: 'teaGardenService.total_value', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 9, thStyle: { width: '9%' } },
          { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 10, thStyle: { width: '12%' } }
        ],
        actions: {
          edit: true,
          details: true,
          toogle: true,
          delete: false
        }
      }
    },
    created () {
      this.loadData()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      }
    },
    computed: {
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        keys = [
          { key: 'serial' },
          { key: 'auction_no' },
          { key: 'fiscal_year_id' },
          { key: 'auction_date' },
          { key: 'auction_location' },
          { key: 'broker_id' },
          { key: 'total_sale_quantity' },
          { key: 'total_average_value' },
          { key: 'total_value' },
          { key: 'action' }
        ]
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('teaGardenService.auction_sale') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGardenService.auction_sale') + ' ' + this.$t('globalTrans.modify')
      },
      detailsTitle () {
        return this.$t('teaGardenService.auction_sale') + ' ' + this.$t('globalTrans.details')
      },
      currentLocale () {
          return this.$i18n.locale
      },
      fiscalYearList () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      masterBrokerInfoList () {
        return this.$store.state.TeaGardenService.commonObj.masterBrokerInfoList.filter(item => item.status === 1)
      },
      locationList () {
        return [
          {
            value: 1, text_en: 'Chattogram', text_bn: 'চট্টগ্রাম', text: this.currentLocale === 'en' ? 'Chattogram' : 'চট্টগ্রাম'
          },
          {
            value: 2, text_en: 'Sylhet', text_bn: 'সিলেট', text: this.currentLocale === 'en' ? 'Sylhet' : 'সিলেট'
          },
          {
            value: 3, text_en: 'Panchagarh', text_bn: 'পঞ্চগড়', text: this.currentLocale === 'en' ? 'Panchagarh' : 'পঞ্চগড়'
          }
        ]
      }
    },
    methods: {
      searchData () {
        this.loadData()
      },
      edit (item) {
          this.editItemId = item.id
      },
      async loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, auctionSaleList, params)
        if (result.success) {
          const listData = result.data.data.map((item, index) => {
            return Object.assign({}, item, { serial: index })
          })
          this.$store.dispatch('setList', listData)
          this.paginationData(result.data, this.search.limit)
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      remove (item) {
        this.changeStatus(3, teaGardenServiceBaseUrl, brokersInfoToggleApi, item)
      },
      details (item) {
          this.item = item
          this.editItemId = item.id
      },
      getFiscalYearName (id) {
        const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getAuctionLocationName (id) {
        const obj = this.locationList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getBrokerName (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterBrokerInfoList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    }
  }
  </script>
<style>
  .btn-success {
    background-color: var(--success) !important;
    border-color: var(--success);
  }
  </style>
