<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <h6 style="background-color: var(--gray-ddd);" class="text-sm-left p-2 my-4">{{ $t('teaGardenService.total_sale_info') }}</h6>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Auction No" vid="auction_no" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="auction_no">
                <template v-slot:label>
                  {{ $t('teaGardenService.auction_no') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="auction_no"
                  v-model="formData.auction_no"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Auction Year" vid="fiscal_year_id" rules="required|min_value:1">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="fiscal_year_id">
                <template v-slot:label>
                  {{ $t('teaGardenService.season') }} <span class="text-danger">*</span>
                </template>
                <b-form-select
                      plain
                      v-model="formData.fiscal_year_id"
                      :options="fiscalYearList"
                      id="fiscal_year_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Auction Date" vid="auction_date" rules="required">
              <b-form-group
                class="row"
                label-for="auction_date"
                slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('teaGardenService.auction_date') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="auction_date"
                  v-model="formData.auction_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :locale="currentLocale"
                  :config="config"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Auction Location" vid="auction_location" rules="required|min_value:1">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="auction_location">
                <template v-slot:label>
                  {{ $t('teaGardenService.auction_location') }} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="formData.auction_location"
                  :options="locationList"
                  id="auction_location"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Broker Name" vid="broker_id" rules="required|min_value:1">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="broker_id">
                <template v-slot:label>
                  {{ $t('teaGardenConfig.broker_name') }} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="formData.broker_id"
                  :options="masterBrokerInfoList"
                  id="auction_location"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Offering Quantity (Kg)" vid="offering_quantity" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="offering_quantity">
                <template v-slot:label>
                  {{ $t('teaGardenService.offering_quantity') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="offering_quantity"
                  v-model="formData.offering_quantity"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  @keypress="isNumber"
                  min="0"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="CST Sale Quantity (Kg)" vid="cst_sale_quantity" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="cst_sale_quantity">
                <template v-slot:label>
                  {{ $t('teaGardenService.cst_sale_quantity') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="cst_sale_quantity"
                  v-model.number="formData.cst_sale_quantity"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  @keypress="isNumber"
                  @input="setTotalSaleQty"
                  min="0"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="CST Average Value (Tk/Per Kg.)" vid="cst_average_value" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="cst_average_value">
                <template v-slot:label>
                  {{ $t('teaGardenService.cst_average_value') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="cst_average_value"
                  v-model="formData.cst_average_value"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  @keypress="isNumber"
                  @input="setTotalSaleQty"
                  min="0"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="OST Sale Quantity (Kg)" vid="ost_sale_quantity" rules="">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="ost_sale_quantity">
                <template v-slot:label>
                  {{ $t('teaGardenService.ost_sale_quantity') }}
                </template>
                <b-form-input
                  id="ost_sale_quantity"
                  v-model="formData.ost_sale_quantity"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  @keypress="isNumber"
                  @input="setTotalSaleQty"
                  min="0"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="OST Average Value (Tk/Per Kg.)" vid="ost_average_value" rules="">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="ost_average_value">
                <template v-slot:label>
                  {{ $t('teaGardenService.ost_average_value') }}
                </template>
                <b-form-input
                  id="ost_average_value"
                  v-model="formData.ost_average_value"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  @keypress="isNumber"
                  @input="setTotalSaleQty"
                  min="0"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Total Sale Quantity (Kg)" vid="total_sale_quantity" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="total_sale_quantity">
                <template v-slot:label>
                  {{ $t('teaGardenService.total_sale_quantity') }}
                </template>
                <b-form-input
                  id="total_sale_quantity"
                  v-model="formData.total_sale_quantity"
                  :state="errors[0] ? false : (valid ? true : null)"
                  disabled
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Total Value (Tk)" vid="total_value" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="total_value">
                <template v-slot:label>
                  {{ $t('teaGardenService.total_value') }}
                </template>
                <b-form-input
                  id="total_value"
                  v-model="formData.total_value"
                  :state="errors[0] ? false : (valid ? true : null)"
                  disabled
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Total Average Value (Tk)" vid="total_average" rules="">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="total_average">
                <template v-slot:label>
                  {{ $t('teaGardenService.total_average_value') }}
                </template>
                <b-form-input
                  id="total_average"
                  v-model="formData.total_average_value"
                  :state="errors[0] ? false : (valid ? true : null)"
                  disabled
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <h6 style="background-color: var(--gray-ddd);" class="text-sm-left p-2 my-4">{{ $t('teaGardenService.internal_export_sale_info') }}</h6>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Internal Account (Kg)" vid="internal_account" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="internal_account">
                <template v-slot:label>
                  {{ $t('teaGardenService.internal_account') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="internal_account"
                  v-model="formData.internal_account"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  @keypress="isNumber"
                  @input="setTotalQty"
                  min="0"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Auction Export Account (Kg)" vid="auction_export_account" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="auction_export_account">
                <template v-slot:label>
                  {{ $t('teaGardenService.auction_export_account') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="auction_export_account"
                  v-model="formData.auction_export_account"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  @keypress="isNumber"
                  @input="setTotalQty"
                  min="0"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Total Quantity (Kg)" vid="total_quantity" rules="">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="total_quantity">
                <template v-slot:label>
                  {{ $t('teaGardenService.total_quantity') }}
                </template>
                <b-form-input
                  id="total_quantity"
                  v-model="formData.total_quantity"
                  :state="errors[0] ? false : (valid ? true : null)"
                  disabled
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
            <b-button type="submit" @click="formData.status = 2" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
            <b-button type="submit" @click="formData.status = 1" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button>

            <!-- <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button> -->
            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { auctionSaleStore, auctionSaleUpdate } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'

export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        auction_no: '',
        fiscal_year_id: this.$store.state.currentFiscalYearId,
        auction_date: '',
        auction_location: 0,
        broker_id: 0,
        offering_quantity: '',
        cst_sale_quantity: '',
        cst_average_value: '',
        ost_sale_quantity: '',
        ost_average_value: '',
        total_sale_quantity: '',
        total_value: '',
        total_average_value: '',
        internal_account: '',
        auction_export_account: '',
        total_quantity: ''
      },
      config: { static: true }
    }
  },
  created () {
    if (this.id) {
      this.formData = this.getFormData()
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    masterBrokerInfoList () {
      return this.$store.state.TeaGardenService.commonObj.masterBrokerInfoList.filter(item => item.status === 1)
    },
    locationList () {
      return [
        {
          value: 1, text_en: 'Chattogram', text_bn: 'চট্টগ্রাম', text: this.currentLocale === 'en' ? 'Chattogram' : 'চট্টগ্রাম'
        },
        {
          value: 2, text_en: 'Sylhet', text_bn: 'সিলেট', text: this.currentLocale === 'en' ? 'Sylhet' : 'সিলেট'
        },
        {
          value: 3, text_en: 'Panchagarh', text_bn: 'পঞ্চগড়', text: this.currentLocale === 'en' ? 'Panchagarh' : 'পঞ্চগড়'
        }
      ]
    }
  },
  methods: {
    getFormData () {
      const tempData = this.$store.state.list.find(item => item.id === parseInt(this.id))
      return JSON.parse(JSON.stringify(tempData))
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${auctionSaleUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, auctionSaleStore, this.formData)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    setTotalSaleQty () {
      const cstSaleQty = this.formData.cst_sale_quantity ? parseFloat(this.formData.cst_sale_quantity) : 0
      const cstAvgValue = this.formData.cst_average_value ? parseFloat(this.formData.cst_average_value) : 0
      const ostSaleQty = this.formData.ost_sale_quantity ? parseFloat(this.formData.ost_sale_quantity) : 0
      const ostAvgValue = this.formData.ost_average_value ? parseFloat(this.formData.ost_average_value) : 0
      const totalSaleQty = cstSaleQty + ostSaleQty
      this.formData.total_sale_quantity = totalSaleQty
      const totalValue = (cstSaleQty * cstAvgValue) + (ostSaleQty * ostAvgValue)
      this.formData.total_value = totalValue
      this.formData.total_average_value = (totalValue / totalSaleQty).toFixed(2)
    },
    setTotalQty () {
      const internalAccount = this.formData.internal_account ? parseFloat(this.formData.internal_account) : 0
      const auctionExportAccount = this.formData.auction_export_account ? parseFloat(this.formData.auction_export_account) : 0
      this.formData.total_quantity = internalAccount + auctionExportAccount
    },
    isNumber (evt) {
      helpers.isNumber(evt)
    }
  },
  watch: {
  }
}
</script>
