<template>
      <b-row>
        <b-col lg="12" sm="12">
          <body-card>
            <template>
              <b-row>
              <b-col lg="12" sm="12">
                <h6 style="background-color: var(--gray-ddd);" class="text-sm-left p-2 mb-4">{{ $t('teaGardenService.total_sale_info') }}</h6>
                <table class="table table-borderless">
                  <tr>
                    <th width="30%"> {{ $t('teaGardenService.auction_no') }}</th>
                    <th width="2%">:</th>
                    <td width="18%">{{ $n(item.auction_no, { useGrouping: false }) }}</td>
                    <th width="30%"> {{ $t('teaGardenService.season') }}</th>
                    <th width="2%">:</th>
                    <td width="18%">{{ getFiscalYearName(item.fiscal_year_id) }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('teaGardenService.auction_date') }}</th>
                    <th>:</th>
                    <td>{{ item.auction_date | dateFormat }}</td>
                    <th> {{ $t('teaGardenService.auction_location') }}</th>
                    <th>:</th>
                    <td>{{ getAuctionLocationName(item.auction_location) }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('teaGardenConfig.broker_name') }}</th>
                    <th>:</th>
                    <td>{{ getBrokerName(item.broker_id) }}</td>
                    <th> {{ $t('teaGardenService.offering_quantity') }}</th>
                    <th>:</th>
                    <td>{{ $n(item.offering_quantity) }} </td>
                  </tr>
                  <tr>
                    <th> {{ $t('teaGardenService.cst_sale_quantity') }}</th>
                    <th>:</th>
                    <td>{{ $n(item.cst_sale_quantity) }} </td>
                    <th> {{ $t('teaGardenService.cst_average_value') }}</th>
                    <th>:</th>
                    <td>{{ $n(item.cst_average_value) }} </td>
                  </tr>
                  <tr>
                    <th> {{ $t('teaGardenService.ost_sale_quantity') }}</th>
                    <th>:</th>
                    <td>{{ $n(item.ost_sale_quantity) }} </td>
                    <th>{{ $t('teaGardenService.total_sale_quantity') }}</th>
                    <th>:</th>
                    <td colspan="3">{{ $n(item.total_sale_quantity) }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('teaGardenService.total_value') }}</th>
                    <th>:</th>
                    <td>{{ $n(item.total_value) }}</td>
                    <th> {{ $t('teaGardenService.total_average_value') }}</th>
                    <th>:</th>
                    <td>{{ $n(item.total_average_value) }}</td>
                  </tr>
                </table>
                <h6 style="background-color: var(--gray-ddd);" class="text-sm-left p-2 my-4">{{ $t('teaGardenService.internal_export_sale_info') }}</h6>
                <table class="table table-borderless">
                  <tr>
                    <th width="30%"> {{ $t('teaGardenService.internal_account') }}</th>
                    <th width="2%">:</th>
                    <td width="18%">{{ $n(item.internal_account) }}</td>
                    <th width="30%">{{ $t('teaGardenService.auction_export_account') }}</th>
                    <th width="2%">:</th>
                    <td width="18%">{{ $n(item.auction_export_account) }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('teaGardenService.total_quantity') }}</th>
                    <th>:</th>
                    <td>{{ $n(item.total_quantity) }}</td>
                  </tr>
                </table>
              </b-col>
            </b-row>
            </template>
          </body-card>
        </b-col>
      </b-row>
</template>
<script>
import { teaGardenServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'Details',
  props: ['item'],
  data () {
    return {
      loading: false,
      errors: [],
      detailsData: [],
      baseUrl: teaGardenServiceBaseUrl,
      detailsItemId: ''
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    locationList () {
      return [
        {
          value: 1, text_en: 'Chattogram', text_bn: 'চট্টগ্রাম', text: this.currentLocale === 'en' ? 'Chattogram' : 'চট্টগ্রাম'
        },
        {
          value: 2, text_en: 'Sylhet', text_bn: 'সিলেট', text: this.currentLocale === 'en' ? 'Sylhet' : 'সিলেট'
        },
        {
          value: 3, text_en: 'Panchagarh', text_bn: 'পঞ্চগড়', text: this.currentLocale === 'en' ? 'Panchagarh' : 'পঞ্চগড়'
        }
      ]
    }
  },
  methods: {
        getFiscalYearName (id) {
          const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getAuctionLocationName (id) {
          const obj = this.locationList.find(item => item.value === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getBrokerName (id) {
          const obj = this.$store.state.TeaGardenService.commonObj.masterBrokerInfoList.find(item => item.value === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        }
  }
}
</script>
